import React, { PureComponent } from "react"
import MediaPage from "../components/pages/mediaPage"
import LandingPage from "../components/pages/landingPage"

class IndexPage extends PureComponent {
  showPage = () => {
    // if (
    //   typeof window !== "undefined" &&
    //   window.location.href.indexOf("media") > -1
    // ) {
    //   return <MediaPage data={this.props.data} />
    // } else {
    //   return <LandingPage data={this.props.data} />
    // }
    return <MediaPage data={this.props.data} />
  }
  render() {
    return this.showPage()
  }
}

export default IndexPage

export const query = graphql`
  {
    projects: allWordpressWpProjects {
      edges {
        node {
          categories {
            name
          }
          acf {
            subtitle
            title
            preview_video {
              localFile {
                url
              }
            }
            preview_video_thumbnail {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          slug
        }
      }
    }
    heroSection: allWordpressWpHomeHeroSection {
      edges {
        node {
          acf {
            page_link
            video_link {
              localFile {
                url
              }
            }
            video_thumb {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    heroImg: file(relativePath: { eq: "landing-page/main-hero-pic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoWithTextImg: file(relativePath: { eq: "framehublogo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoImg: file(relativePath: { eq: "favicon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Dashboard0Img: file(relativePath: { eq: "landing-page/Dashboard-0.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Dashboard1Img: file(relativePath: { eq: "landing-page/Dashboard-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Dashboard2Img: file(relativePath: { eq: "landing-page/Dashboard-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeImg: file(relativePath: { eq: "landing-page/home.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    typo3Img: file(relativePath: { eq: "landing-page/typo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wordpressImg: file(relativePath: { eq: "landing-page/wordpress.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    customIconImg: file(relativePath: { eq: "landing-page/custom-icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contentfulCmsImg: file(
      relativePath: { eq: "landing-page/contentful-cms.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wlImg: file(relativePath: { eq: "landing-page/wl.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bottomArrow: file(relativePath: { eq: "botton-arrow.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
