import React from 'react';

const Loader = () => (
  <div className="loader-container">
    <div className="loader-inner-container ball-scale-multiple">
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Loader;
