import React, { PureComponent } from "react"

import Layout from "../layout"
import SEO from "../seo"
import CustomVideoPlayer from "../CustomVideoPlayer"
import Modal from "../modal"
import ModalContent from "../ModalContent"

import bastraLogo from "../../images/client-logos/bastra.png";
import bertelsmannStiftungLogo from "../../images/client-logos/Bertelsmann-Stiftung.png";
import bild4Logo from "../../images/client-logos/Bild4.png";
import bild5Logo from "../../images/client-logos/Bild5.png";
import bild6Logo from "../../images/client-logos/Bild6.png";
import foundersfoundationLogo from "../../images/client-logos/foundersfoundation.png";
import logoKopieLogo from "../../images/client-logos/logoKopie.png";
import marantecLogo from "../../images/client-logos/marantec.png";
import whuLogo from "../../images/client-logos/whu.png";

class IndexPage extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  toggleModal = () => {
		this.setState({
			isOpen: !this.state.isOpen
		});
  };
  
  setVideos = (projects) => {

    let videosData = projects.edges;

    let result = []

    let j = 0;

    let remInCompleteProjects = videosData.filter(item => item.node.acf.preview_video !== null && item.node.acf.preview_video_thumbnail);

    for (let i = 0; i < remInCompleteProjects.length; i++) {
      if (remInCompleteProjects[i].node.categories === undefined || remInCompleteProjects[i].node.categories == null) {
        if (j % 3 === 0) {
          j++;
          result.push(
            <div
              key={i}
              className={i !== 0 ? "row mt-3 pt-md-4 pt-sm-0 pt-0" : "row"}
            >
              <div className="col-md-12 pl-lg-4 pl-md-4 pr-lg-4 pr-md-4 vid-lg">
                <CustomVideoPlayer
                  videoSrc={remInCompleteProjects[i].node.acf.preview_video.localFile.url}
                  videoThumb={remInCompleteProjects[i].node.acf.preview_video_thumbnail.localFile.childImageSharp.fluid}
                  title={remInCompleteProjects[i].node.acf.title}
                  subtitle={remInCompleteProjects[i].node.acf.subtitle}
                  link={remInCompleteProjects[i].node.slug}
                />
              </div>
            </div>
          )
        } else {
          j++;j++;
          result.push(
            <div key={i} className="row">
              <div className="col-md-6 col-sm-12 mt-3 pt-md-4 pt-sm-0 pt-0 pl-lg-4 pl-md-4 vid-sm">
                <CustomVideoPlayer
                  videoSrc={remInCompleteProjects[i].node.acf.preview_video.localFile.url}
                  videoThumb={remInCompleteProjects[i].node.acf.preview_video_thumbnail.localFile.childImageSharp.fluid}
                  title={remInCompleteProjects[i].node.acf.title}
                  subtitle={remInCompleteProjects[i].node.acf.subtitle}
                  link={remInCompleteProjects[i].node.slug}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3 pt-md-4 pt-sm-0 pt-0 pr-lg-4 pr-md-4 vid-sm">
                {++i < remInCompleteProjects.length && (
                  <CustomVideoPlayer
                    videoSrc={remInCompleteProjects[i].node.acf.preview_video.localFile.url}
                    videoThumb={remInCompleteProjects[i].node.acf.preview_video_thumbnail.localFile.childImageSharp.fluid}
                    title={remInCompleteProjects[i].node.acf.title}
                    subtitle={remInCompleteProjects[i].node.acf.subtitle}
                    link={remInCompleteProjects[i].node.slug}
                  />
                )}
              </div>
            </div>
          )
        }
      }
    }

    return result
  }

  render() {
    const { projects, heroSection } = this.props.data;

    const heroSectionContent = heroSection.edges[0].node.acf

    return (
      <Layout>
        <SEO title="Framehub" />
        <Modal
          show={this.state.isOpen}
          onClose={this.toggleModal}
          overlayClass="modal-overlay"
          contentClass="modal-content"
        >
          <ModalContent />
        </Modal>
        <CustomVideoPlayer
          videoSrc={heroSectionContent.video_link.localFile.url}
          videoThumb={heroSectionContent.video_thumb.localFile.childImageSharp.fluid}
          link={heroSectionContent.page_link}
          isHeroVideo={true}
          stopHoverPlayEffect={true}
          containerCssClasses="hero-video-hight"
          toggleModal={this.toggleModal}
        />
        <div id="content" className="page-content-container">
          <div className="client-logos-container pt-lg-4 pt-md-4 pt-0 mt-4 mb-4 pb-0">
            <img src={foundersfoundationLogo} alt="logo" />
            <img src={bertelsmannStiftungLogo} alt="logo" />
            <img src={bild6Logo} alt="logo" />
            <img src={logoKopieLogo} alt="logo" />
            <img src={bild4Logo} alt="logo" />
            <img src={whuLogo} alt="logo" />
            <img src={bastraLogo} alt="logo" />
            <img src={bild5Logo} alt="logo" />
            <img src={marantecLogo} alt="logo" />
          </div>
          <div className="container padding-tp-80 mb-4 pb-4">
            {this.setVideos(projects)}
          </div>
          <div className="container-fluid talk-to-outter-container">
            <div className="talk-to-container">
              <div className="container">
                <section>
                  <h2 className="h2">We're excited to hear from you</h2>
                  <p>
                  We'd love to get in touch. Feel free to ask us everything - we are thrilled to learn more about your story.
                  </p>
                  <a className="projects-link-2" href="mailto:team@framehub.io">
                    Drop us a mail
                  </a>
                </section>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
