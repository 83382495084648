import React from "react";
import PropTypes from "prop-types";

class Modal extends React.Component {
	render() {
		if (!this.props.show) {
			return null;
		}
		return (
			<div
				onClick={this.props.onClose}
				className={`modal-overlay ${this.props.overlayClass}`}
				style={this.props.overlayInlineStyle}
			>
				<div className="modal-inner-container">
					<span onClick={this.props.onClose} className="close">
						&times;
					</span>
					<div
						className={`modal-content ${this.props.contentClass}`}
						style={this.props.contentInlineStyle}
					>
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

Modal.propTypes = {
	onClose: PropTypes.func.isRequired,
	show: PropTypes.bool,
	children: PropTypes.node,
	overlayClass: PropTypes.string,
	contentClass: PropTypes.string,
	overlayInlineStyle: PropTypes.object,
	contentInlineStyle: PropTypes.object
};

export default Modal;
