import React from "react"

const ModalContent = () => (
  <React.Fragment>
    <h4 className="modal-heading">
      About Us
    </h4>
    <p className="quote">
      "On the edge of digital storytelling"
    </p>
    <p className="description">
    We are a collective of filmmakers, storytellers & technologists - based in Bielefeld, Germany - striving to level up corporate communication.
    <br /><br />
    We are here to help you tell your story by working on the edge of technological progress.
    </p>
  </React.Fragment>
)

export default ModalContent
