import React from "react"

const PlugPlayType = props => (
  <div className="plug-play-item-cont">
    <img src={props.imgSrc} alt="pp-img" />
    <p>{props.text}</p>
  </div>
)

export default PlugPlayType
