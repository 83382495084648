import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"
import { Link } from "gatsby"
import posed from "react-pose"
import Img from "gatsby-image"
import Scrollchor from "react-scrollchor"

import Modal from "./modal"
import Loader from "../components/loader"

import LogoWithText from "../images/logo-with-text1.png"
import ModalContent from "./ModalContent"

const ContentPose = posed.div({
  hidden: { 
    y: +50,
    opacity: 0,
    transition: {
      y: ({from,to}) => ({ type: 'keyframes', values: [from, 50, to], times: [0, 0.99, 1]}),
      opacity: ({ from, to }) => ({ type: 'keyframes', values: [from, 0, to], times: [0, 0.99, 1] })
    }
  },
  visible: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      y: { type: 'spring' },
      default: { duration: 900 }
    }
  }
});

class CustomVideoPlayer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      playCurrVideo: this.props.isHeroVideo ? this.props.isHeroVideo : this.props.playCurrVideo,
      displayContent: false,
      showLoader: true,
      isOpen: false
    }
  }

  toggleModal = () => {
		this.setState({
			isOpen: !this.state.isOpen
		});
	};

  playVideo = () => {
    if (!this.props.stopHoverPlayEffect) {
      this.setState({
        playCurrVideo: true,
        displayContent: true
      })
    }
  }

  stopVideo = () => {
    if (!this.props.stopHoverPlayEffect) {
      this.setState({
        playCurrVideo: false,
        displayContent: false
      })
    }
  }

  ref = player => {
    this.player = player
  }

  hideLoader = () => {
    this.setState({
      showLoader: false
    })
  }

  renderContent = () => {
    let props = this.props

    return (
      <Fragment>
        {this.state.showLoader && props.isHeroVideo && !props.noInfo &&
          <Loader />
        }
        <ReactPlayer
          ref={this.ref}
          className={props.noInfo || props.isHeroVideo ? "video-no-overlay " + props.reactPlayerCustomCssClasses : this.state.playCurrVideo && !this.state.showLoader ? "video" + props.reactPlayerCustomCssClasses : "no-display"}
          url={props.videoSrc}
          playsinline
          muted={true}//{props.noInfo || props.isHeroVideo ? props.muted : false}
          //controls={props.showControls}
          loop={true}
          playing={props.isHeroVideo ? (props.isViemoVideo ? false : true) : this.state.playCurrVideo}
          preload={true}
          style={props.customStyles}
          onPause={() =>  this.player.seekTo(0, 'seconds')}
          onError={error => console.log(error)}
          onReady={this.hideLoader}
          config={{
            vimeo:{
              playerOptions: {
                controls: true,
                allowfullscreen: true
              }
            }
          }}
        />

        {(!props.noInfo || !props.isHeroVideo) &&
          <Img
            className={this.state.playCurrVideo && this.state.showLoader && !props.isHeroVideo ? "video video-thumbnail" + props.reactPlayerCustomCssClasses : "no-display"}
            fluid={props.videoThumb}
            alt="thumbnail"
          />
        }
        
        {(!props.noInfo || !props.isHeroVideo) &&
          <Img
            className={!this.state.playCurrVideo ? "video video-thumbnail" + props.reactPlayerCustomCssClasses : "no-display"}
            fluid={props.videoThumb}
            alt="thumbnail"
          />
        }

        {!props.isHeroVideo && !props.noInfo && (
          <Fragment>
            <div
              className={
                "video-content-container display-desktop " + props.contentContainerCssClasses
              }
            >
              <ContentPose className="content-container" pose={this.state.displayContent ? 'visible' : 'hidden'}>
                <div className="vid-subtitle">{props.subtitle}</div>
                <div className="vid-title">{props.title}</div>
              </ContentPose>
            </div>
            <div
              className={"video-content-container display-mobile " + props.contentContainerCssClasses}
            >
              <div className="content-container">
                <div className="vid-subtitle">{props.subtitle}</div>
                <div className="vid-title">{props.title}</div>
              </div>
            </div>
          </Fragment>
        )}
        {props.isHeroVideo && !props.noInfo && (
          <div>
            <div
              className={
                "hero-video-content-container " + props.contentContainerCssClasses
              }
            >
              <div className="content-container">
                <p><img src={LogoWithText} alt="logo-with-text" /></p>
                <div className="hero-btn-container">
                  <Link className="hero-btn" to={props.link}>Play</Link>
                  <a href="#" onClick={this.toggleModal} className="hero-btn display-desktop">About Us</a>
                  <a href="#" onClick={this.props.toggleModal} className="hero-btn display-mb">About Us</a>
                </div>
              </div>
            </div>
            <div className="ip-scroll-arrow-cont position-relative">
              <Scrollchor to="#content" className="scroll-arrow sa-landing"> </Scrollchor>
            </div>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    let props = this.props
    return (
      <Fragment>
        {props.link !== "#" && !props.isHeroVideo &&
          <Link
            to={props.link}
            onMouseEnter={this.playVideo}
            onMouseLeave={this.stopVideo}
            onClick={this.stopVideo}
            onTouchStart={this.stopVideo}
            onTouchMove={this.stopVideo}
            onTouchEnd={this.stopVideo}
            onTouchCancel={this.stopVideo}
            className={"custom-video-container " + props.containerCssClasses}
          >
            {this.renderContent()}
          </Link> 
        }
        {(props.link === "#" || props.isHeroVideo) &&
          <div
            onMouseEnter={this.playVideo}
            onMouseLeave={this.stopVideo}
            onClick={this.stopVideo}
            onTouchStart={this.stopVideo}
            onTouchMove={this.stopVideo}
            onTouchEnd={this.stopVideo}
            onTouchCancel={this.stopVideo}
            className={"custom-video-container " + props.containerCssClasses}
          >
            {this.renderContent()}
            <Modal
              show={this.state.isOpen}
              onClose={this.toggleModal}
              overlayClass="modal-overlay"
              contentClass="modal-content"
            >
              <ModalContent />
            </Modal>
          </div>
        }
      </Fragment>
    )
  }
}

CustomVideoPlayer.defaultProps = {
  muted: true,
  videoThumb: "",
  showControls: false,
  playCurrVideo: false,
  subtitle: "",
  title: "",
  containerCssClasses: "",
  contentContainerCssClasses: "",
  reactPlayerCustomCssClasses: "",
  isHeroVideo: false,
  linkText: "",
  noInfo: false,
  stopHoverPlayEffect: false,
  link: "#",
  isViemoVideo: false
}

CustomVideoPlayer.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  videoThumb: PropTypes.object,
  muted: PropTypes.bool,
  containerCssClasses: PropTypes.string,
  contentContainerCssClasses: PropTypes.string,
  reactPlayerCustomCssClasses: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  showControls: PropTypes.bool,
  playCurrVideo: PropTypes.bool,
  isHeroVideo: PropTypes.bool,
  linkText: PropTypes.string,
  noInfo: PropTypes.bool,
  stopHoverPlayEffect: PropTypes.bool,
  link: PropTypes.string,
  customStyles: PropTypes.object,
  toggleModal: PropTypes.func,
  isViemoVideo: PropTypes.bool
}

export default CustomVideoPlayer
